import axios from 'axios';
import { getBaseUrl } from '../../../api-services/environment-url/environment-url';


const LANG_API = axios.create({
  baseURL: getBaseUrl(process.env.REACT_APP_NODE_ENV, 'REACT_APP_LANG_URL'),
  timeout: 10000, // 10 seconds
});

export default LANG_API;
