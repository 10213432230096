import React from 'react';
import './AccessRedirect.scss';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
type StoreProps={
  langData:any;
}
const AccessRedirect: React.FC<StoreProps> = ({langData}) => {
  return (
    <>
      <div className="ion-page access-redirect-page">
      {langData?.['access_redirect_txt']}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    langData:state.common.langData
  };
};

export default connect(mapStateToProps, null)(AccessRedirect);
